<template>
	<div class="project-main-wrapper">
		<div class="project anim-opacity-top" ref="project">
			<div class="project-wrapper" ref="projectWrapper">
				<div class="img-header" ref="imgheader">
					<p id="ih2">{{ choosenProject.name }}</p>
					<img class="closerIcon" @click="closeProject" ref="closerimg" src="../assets/icons/close.png" alt="close" />
					<div class="color-grad" ref="colorGrad">
						<svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
							<defs>
								<radialGradient r="0.5" cy="0.5" cx="0.5" spreadMethod="pad" id="svg_5">
									<stop offset="0" stop-opacity="0.5" :stop-color="choosenProject.bgColor" />
									<stop offset="1" stop-opacity="0" stop-color="#ffffff" />
								</radialGradient>
							</defs>
							<g>
								<title>Layer 1</title>
								<ellipse ry="80" rx="80" id="svg_7" cy="80" cx="80" fill="url(#svg_5)" />
							</g>
						</svg>
					</div>
				</div>
				<div class="img-description" ref="imgdes">
					<div class="isd">
						<p>{{ choosenProject.lDescription }}</p>
					</div>
					<div class="ist">
						<p>{{ choosenProject.type }}</p>
						<p id="ih3">{{ choosenProject.date }}</p>
					</div>
				</div>
				<div class="submedia anim-opacity-top" ref="submedia">
					<div class="subinner" v-for="item in choosenProject.subpaths">
						<img :src="item" alt="sub image" v-if="item.substr(item.lastIndexOf('.') + 1) !== 'mp4'" />

						<video controls v-if="item.substr(item.lastIndexOf('.') + 1) == 'mp4'">
							<source :src="item + '#t=0.1'" type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
import Projects from "../components/Projects";
import Footer from "../components/Footer.vue";

export default {
	name: "Home",
	components: {
		Projects,
		Footer,
	},
	data() {
		return {
			Projects,
			choosenProject: Object,
			animation: false, // toggle animation based on image position
		};
	},
	methods: {
		closeProject() {
			this.$router.push("/work#" + this.choosenProject.name.toLowerCase().replace(/\s+/g, ""));
		},
	},

	mounted() {
		for (let a = 0; a < this.Projects.length; a++) {
			if (this.$route.params.project === this.Projects[a].name.toLowerCase().replace(/\s+/g, "")) {
				this.choosenProject = this.Projects[a];
			}
		}

		//this.scroll.init();
	},
	updated() {
		let distance = 80;
		let elemOffset = this.$store.state.offset; // offset of clicked image relative to viewport
		let textOffset = this.$refs.projectWrapper.getBoundingClientRect().top;
		let posDiff = Math.ceil(elemOffset - textOffset - distance * 3); // get offset of media plus text above to match pos

		if (this.animation == true) {
			this.$refs.submedia.style.top = posDiff + "px";
		} else {
			this.$refs.submedia.style.top = distance + "px";
		}

		setTimeout(() => {
			this.$refs.project.style.opacity = "1";
			this.$refs.submedia.style.top = "0px";
			this.$refs.closerimg.style.opacity = "1";
		}, 500);
	},
};
</script>

<style scoped>
.project {
	position: relative;
	top: 0;
	opacity: 0;
	z-index: 1;
	/*animater*/
}
.project-wrapper {
	width: calc(100% - 5%);
	margin: 0 0 0 2.5%;
	padding: 80px 0 0 0;
}
#ih1 {
	font-size: 0.7rem;
}
#ih2 {
	position: relative;
	font-size: 2rem;
	font-family: "RobotoMedium";
	z-index: 2;
}
#ih3 {
	font-size: 0.7rem;
	align-self: flex-end;
}
.color-grad {
	position: absolute;
	margin: 0 0 0 -80px;
	z-index: 1;
}
.img-header {
	width: 90%;
	margin: 0 0 0 5%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.closerIcon {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.img-holder img {
	width: 100%;
	margin: 0 0 calc(2.5% - 2px) 0;
}
.video-holder video {
	width: 100%;
}
.img-description {
	position: relative;
	display: block;
	width: 90%;
	margin: 10px 0 10px 5%;
	z-index: 2;
}
.isd {
	font-size: 0.8rem;
	text-transform: uppercase;
	margin: 0 0 40px 0;
}
.ist {
	font-size: 0.8rem;
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
}

.submedia {
	margin: 10vh 0 0 0;
	position: relative;
}
.subinner {
	margin-bottom: 2.5%;
}
.submedia img,
.submedia video {
	width: 100%;
}

@media only screen and (min-width: 800px) {
	.project {
		width: 70%;
		margin: 0 0 0 15%;
	}
	#ih2 {
		font-size: 3rem;
		font-family: "RobotoMedium";
	}
	.img-header {
		width: 80%;
		margin: 0 0 0 10%;
	}
	.img-description {
		width: 80%;
		margin: 0 0 0 10%;
	}
}
</style>
